<template>
  <div id='cal-content'>
    <div class='breadcast'
         style="text-align:right">
      <el-button type='primary'
                 @click='$router.go(-1)'
                 size='medium'>返回</el-button>
    </div>
    <h2 class="title">
      <div class="title_icon">
      </div>
      考勤详情
    </h2>
    <div>
      <el-table :data="tableData"
                style="width: 100%">
        <el-table-column :prop="item.prop"
                         :label="item.label"
                         :width="item.width"
                         v-for="item in tableCols"
                         align='center'
                         :key="item.prop"
                         :formatter="item.formatter"></el-table-column>
      </el-table>
    </div>
    <div class="Slideswitch">
      <span>显示定位时间</span>
      <el-switch v-model="value2"
                 active-color="#13ce66"
                 inactive-color="#C0CCDA"
                 @change="showMethods">
      </el-switch>
    </div>
    <div class='map'>
      <el-amap vid='amap'
               :amap-manager="amapManager"
               class="amap-demo">
        <el-amap-polyline :editable="polyline.editable"
                          :path="polyline.path"
                          :events="polyline.events"></el-amap-polyline>
        <el-amap-marker v-for="(marker, index) in markers"
                        :content='marker.content'
                        :position="marker.position"
                        :key='marker.content'
                        :events="marker.events"
                        :offset='[-19, -55]'
                        :visible="marker.visible"
                        :draggable="marker.draggable"
                        :vid="index"></el-amap-marker>
        <el-amap-info-window :position="windowsData[0].position"
                             :content="windowsData[0].content"
                             :visible="windowsData[0].visible"
                             :events="windowsData[0].events"
                             :offset='[0, -48]'>
        </el-amap-info-window>
      </el-amap>
    </div>
  </div>
</template>
<script>
import { AMapManager } from 'vue-amap'
import { setTimeout } from 'timers';
let amapManager = new AMapManager();
export default {
  data() {
    return {
      parkPoints: null,
      value2: 'false',
      Omap: null,
      zoom: 18,
      //  center: [121.5273285, 31.25515044],
      tableData: [],
      amapManager,
      polyline: {
        path: [],
        editable: false
      },
      windowsData: [{
        position: [121.5273285, 31.25515044],
        content: '<span>window</span>',
        events: {},
        visible: false
      }],
      markers: [
        //     {
        //   position: [121.5389385, 31.21515044],
        //   events: {
        //     click: () => {
        //         this.windowsData[0].visible = true;
        //         this.windowsData[0].position = [121.5389385, 31.21515044];
        //         this.windowsData[0].content = '<span>window2344</span>'
        //     }
        //   },
        //   visible: true,
        //   draggable: false,
        //   content: '<span class="up"></span>'
        // }, {
        //   position: [121.5389385, 31.29615044],
        //   events: {
        //     click: () => {
        //       this.windowsData[0].visible = true;
        //       this.windowsData[0].position = [121.5389385, 31.29615044];
        //       this.windowsData[0].content = '<span>windowddddd</span>'
        //     }
        //   },
        //   visible: true,
        //   draggable: false,
        //   content: '<span class="down"></span>'
        // }
      ],
      downAndup: [],
      pointArr: [],
      pathList: [
        // [113.964458, 40.54664],
        // [111.47836, 41.135964],
        // [108.949297, 41.670904],
        // [106.380111, 42.149509],
        // [103.774185, 42.56996],
        // [101.135432, 42.930601],
        // [98.46826, 43.229964],
        // [95.777529, 43.466798],
        // [93.068486, 43.64009],
        // [90.34669, 43.749086],
        // [87.61792, 43.793308]
      ],
      tableCols: [
        {
          prop: 'pdaManagerName',
          label: this.$t("list.full_name"),
          width: ''
        }, {
          prop: 'signDate',
          label: '日期',
          width: '',
          formatter: (row) => {
            return row.signDate.split(' ')[0];
          }
        }, {
          prop: 'signinTime',
          label: '签到时间',
          width: ''
        }, {
          prop: 'signinAddress',
          label: '签到地点',
          width: ''
        }, {
          prop: 'signoutTime',
          label: '签退时间',
          width: ''
        }, {
          prop: 'signoutAddress',
          label: '签退地点',
          width: ''
        }
      ]
    }
  },
  methods: {
    showMethods() {
      if (this.value2 == true) {
        for (let i = 0; i <= this.pathList.length; i++) {
          if (this.pathList[i]) {
            this.markers.push({
              position: [this.pathList[i].longitude / 1000000, this.pathList[i].latitude / 1000000],
              events: {
                click: () => {
                }
              },
              visible: true,
              draggable: false,
              content: `<span :key="${this.pathList.length++}" style="width: 50px;height: 10px;background: black;color: white;border-radius: 5px;">${this.pathList[i].reportHour || ''}</span>`,
              offset: new AMap.Pixel(12, -10)
            })
          }
        }
      } else {
        this.markers = []
        this.setMap([this.downAndup.signinLongitude / 1000000, this.downAndup.signinLatitude / 1000000], [this.downAndup.signoutLongitude / 1000000, this.downAndup.signoutLatitude / 1000000], this.downAndup)
      }
    },
    setMap(startPoint, endPoint, obj) {
      this.markers = [
        {
          position: startPoint,
          events: {
            click: () => {
              this.windowsData[0].visible = true;
              this.windowsData[0].position = startPoint;
              this.windowsData[0].content = `<div>
                        <div>时间：${obj.signinTime}</div>
                        <div>地点：${obj.signinAddress}</div>
                    </div>
                    `
            }
          },
          visible: true,
          draggable: false,
          content: '<span class="up"></span>'
        }, {
          position: endPoint,
          events: {
            click: () => {
              this.windowsData[0].visible = true;
              this.windowsData[0].position = endPoint;
              this.windowsData[0].content = `<div>
                        <div>时间：${obj.signoutTime}</div>
                        <div>地点：${obj.signoutAddress}</div>
                    </div>
                    `
            }
          },
          visible: true,
          draggable: false,
          content: '<span class="down"></span>'
        }
      ]
    },
    handleData(pointData) {
      setTimeout(() => {
        let pointArr = [];
        pointData.forEach(v => {
          let lng = v.longitude / 1000000;
          let lat = v.latitude / 1000000;
          pointArr.push([lng, lat]);
        })
        let o1 = amapManager.getMap();
        this.pointArr = pointArr;
        o1.setCenter(pointArr[0]);
        this.polyline = {
          path: pointArr,
          editable: false
        };
        o1.setFitView();
        // this.setMap(this.polyline.path[0], this.polyline.path[pointArr.length - 1])
      }, 500)
    },
    getData() {
      let obj = this.$route.query;
      let month = '';
      let day = '';
      if (obj.month < 10) {
        month = '0' + obj.month
      } else {
        month = obj.month
      }
      if (obj.day < 10) {
        day = '0' + obj.day
      } else {
        day = obj.day
      }
      let url = '/acb/2.0/pdaManager/attend/monitorList/' +
        obj.year + '-' + month + '-' + day + '/' + obj.pdaManagerId;
      this.$axios.get(url).then(res => {
        if (res.state == 0) {
          this.pointArr = res.value;
          this.pathList = res.value;
          this.getTable(obj.year, month, day, obj.pdaManagerId);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          })
        }
      })
    },
    getTable(year, month, day, pdaManagerId) {
      let url = '/acb/2.0/pdaManager/attend/pdaAttend/' +
        year + '-' + month + '-' + day + '/' + pdaManagerId;
      this.$axios.get(url).then(res => {
        if (res.state == 0) {
          this.downAndup = res.value
          this.tableData = [res.value];
          let o1 = amapManager.getMap();
          o1.setCenter([res.value.signinLongitude / 1000000, res.value.signinLatitude / 1000000]);
          if (res.value.signinLongitude) {
            this.pointArr.push({
              longitude: res.value.signinLongitude,
              latitude: res.value.signinLatitude
            })
          }
          if (res.value.signoutLongitude) {
            this.pointArr.unshift({
              longitude: res.value.signoutLongitude,
              latitude: res.value.signoutLatitude
            })
          }
          this.handleData(this.pointArr);
          this.setMap([res.value.signinLongitude / 1000000, res.value.signinLatitude / 1000000], [res.value.signoutLongitude / 1000000, res.value.signoutLatitude / 1000000], res.value)
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          })
        }
      })
    },
    linepath() {
      let map = amapManager.getMap();
      let that = this;
      AMapUI.load(['ui/misc/PathSimplifier', 'lib/$'], function (PathSimplifier, $) {
        if (!PathSimplifier.supportCanvas) {
          this.$alert('当前环境不支持 Canvas！', this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
          return;
        }
        var pathSimplifierIns = new PathSimplifier({
          zIndex: 200,
          // autoSetFitView:false,
          map: map, // 所属的地图实例
          getPath: function (pathData, pathIndex) {
            // return pathData.path;
            var points = pathData.path
            var lnglatList = [];
            for (var i = 0, len = points.length; i < len; i++) {
              lnglatList.push([points[i].longitude / 1000000, points[i].latitude / 1000000]);
            }
            return lnglatList;
          },
          getHoverTitle: function (pathData, pathIndex, pointIndex) {
            if (pointIndex >= 0) {
              // point
              // 节点展示数据
              return pathData.path[pointIndex].reportHour;
            }
          },
          renderOptions: {
            renderAllPointsIfNumberBelow: 100, // 绘制路线节点，如不需要可设置为-1
            pathLineSelectedStyle: {
              lineWidth: 3,
              strokeStyle: "#4c9d32",
              borderWidth: 1,
              borderStyle: "#cccccc",
              dirArrowStyle: true
            },
            endPointStyle: {
              radius: 4,
              fillStyle: "#eeeeee",
              lineWidth: 1,
              strokeStyle: "#4c9d32"
            },
            keyPointOnSelectedPathLineStyle: {
              radius: 4,
              fillStyle: "#eeeeee",
              lineWidth: 2,
              strokeStyle: "#4c9d32"
            }
          }
        });
        // 设置数据
        pathSimplifierIns.setData([{
          name: '路线0',
          path: that.pathList
        }]);
        // 选中路线0
        pathSimplifierIns.setSelectedPathIndex(0);
      });
    }
  },
  created() {
    setTimeout(() => {
      this.linepath()
    }, 500)
  },
  mounted() {
    this.getData();
  }
}
</script>
<style scope>
.breadcast {
  height: 30px;
}
.map {
  width: 100%;
  height: 500px;
}
.up {
  display: inline-block;
  width: 40px;
  height: 60px;
  background: url("./up.png") no-repeat;
}
.down {
  display: inline-block;
  width: 40px;
  height: 60px;
  background: url("./down.png") no-repeat;
}
.Slideswitch {
  position: absolute;
  z-index: 200;
  right: 30px;
  margin-top: 15px;
}
</style>
